<template>
  <div class="row h-100">
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="col-md-12 my-auto">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-md-6 ">
            <div class="auth-wrapper">
              <div class="auth-content mb-0">

                <div class="card-body">
                  <div class="card-title text-center mb-5 font-18">
                    <div class="text-center subtitle">
                      <img src="/static/images/login/logo.png" alt="logo" class="logo">
                      <br/>
                      <span>{{ $t('login.title1') }}</span> {{ $t('login.title2') }}
                    </div>
                  </div>

                  <div class="text-center mb-3 font-18">
                    {{ $t('forgot-password.title') }}
                  </div>
                  <div class="text-center mb-2 font-11">
                    {{ $t('forgot-password.message') }}
                  </div>

                  <form method="post" @submit.prevent="onSubmit('frmPasswordResetRequest')" data-vv-scope="frmPasswordResetRequest">
                    <div class="mb-4">
                      <custom-input
                        v-model="passwordResetRequest.username"
                        name="login.username"
                        label="E-mail"
                        type="text"
                        rootClassName="md-form mb-0"
                        inputClassName="md-form-control"
                        v-validate="{ required: true, email: true }"
                        :error="errors.first('login.username')">
                      </custom-input>
                    </div>

                    <div class="mb-5 text-center">
                      <button class="btn btn-primary btn-padding" type="submit">{{ $t('resetting.title') }}</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
// import router from '@/router'
import { loginService } from '@/services/loginService'
import CustomInput from '@/components/Forms/CustomInput.vue'
import VeeValidate from 'vee-validate'
import { locale } from '@/utils/validator'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import './login.css'
import '@/assets/css/custom.css'

VeeValidate.Validator.localize({ 'pt_BR': locale })
Vue.use(VeeValidate, { locale: 'pt_BR' })

export default {
  name: 'Login',
  metaInfo: {
    titleTemplate: '%s | Esqueci minha senha'
  },
  components: {
    CustomInput,
    Loading
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      BaseURL: process.env.VUE_APP_BaseURL,
      passwordResetRequest: {
        username: null
      }
    }
  },
  methods: {
    addValidate (field) {
      this.$validator.attach(field.fieldId, field.fieldRules)
    },
    onSubmit (scopeName) {
      let me = this
      this.$validator.validateAll(scopeName).then((result) => {
        if (result) {
          me.isLoading = true
          loginService.passwordResetRequest(this.passwordResetRequest)
            .then(function (res) {
              me.isLoading = false
              if (res) {
                me.$router.push({ name: 'Login' })
              }
            })
            .catch(() => {
              me.isLoading = false
            })
        }
      })
    }
  }
}
</script>

<style scoped>
  .logo-wrapper span {
    font-size: 6vw;
    color: #84754E;
  }

  .card-title span {
    font-weight: 600;
    font-size: 16px;
  }

  .card-title .logo {
    display: block;
    margin: 0 auto 20px;
  }
</style>
